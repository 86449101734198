import React, { Component } from "react"
import { globalHistory } from "@reach/router"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../../components/layout/layout.js"
import SEO from "../../components/seo.js"
import WrappingList from "../../components/buy-equipment/wrapping-list.js"
import TopHeroComponent from "../../components/herocomponents/topherocomponent.js"
import ContactForm from "../../components/bodycomponents/contact-form.js"
import RED_BG from "../../images/background-images/bg-red.svg"
import { colors } from "../../utils/colors.js"
import ABSciexLogo from "../../images/manufacturers/AB-Sciex-Logo.png"
import AgilentLogo from "../../images/manufacturers/Agilent-Technologies-Logo.png"
import BeckmanCoulterLogo from "../../images/manufacturers/Beckman-Coulter-Logo.png"
import BioRadLogo from "../../images/manufacturers/Bio-rad-Logo.png"
import BoschLogo from "../../images/manufacturers/Bosch-Logo.png"
import CytivaLogo from "../../images/manufacturers/Cytiva-Logo.svg"
import GEALogo from "../../images/manufacturers/GEA-Logo.png"
import IMALogo from "../../images/manufacturers/IMA-Group-Logo.png"
import SartoriusLogo from "../../images/manufacturers/Sartorius-Logo.png"
import ThermoFisherLogo from "../../images/manufacturers/Thermo-Fisher-Scientific-Logo.png"
import WatersLogo from "../../images/manufacturers/Waters-Corporation-Logo.png"

class Manufacturers extends Component {
  render() {
    const { data } = this.props
    const canonicalUrl = `https://${globalHistory.location.host}${globalHistory.location.pathname}`

    return (
      <Layout>
        <SEO
          title={`Lab Equipment Manufacturers & Scientific Equipment Companies`}
          description={`Visit Surplus Solutions to explore our wide range of equipment manufacturers. We focus on understanding your needs from the start—contact us today!`}
          canonicalUrl={canonicalUrl}
        />

        <TopHeroComponent
          backgroundImage={RED_BG}
          backgroundColor={colors.scarletRed}
          fontShadowColor={colors.red}
          shadowColor="shadow-red-bottom"
          pageTitle="Browse Manufacturers"
          title="Shop Lab Equipment by Leading Manufacturers"
          body={
            <p className="pt-4">
              Surplus Solutions offers premium new and used laboratory equipment
              from top manufacturers. Browse our extensive list of brands and
              find high-quality lab, packaging, and processing equipment to meet
              your needs.
            </p>
          }
        />

        {/* <Breadcrumb
          paths={[{ name: "Manufacturers", path: "buy-equipment/manufacturers" }]}
        /> */}
        <div className="shadow-custom-out">
          <div className="container max-w-screen-xl pt-14 pb-12">
            <h2 className="text-center">Featured Manufacturers</h2>
            {/* Manufacturer Logos */}
            {/* 4 Logos */}
            <Link className="grid grid-cols-1 md:grid-cols-4 gap-x-20 lg:gap-x-28 justify-items-center items-center text-center pt-8 px-2">
              <Link className="mb-16" to="/manufacturer/AB-Sciex" target="_blank"> 
                <img
                  src={ABSciexLogo}
                  alt="AB Sciex Logo"
                  className="object-contain w-full h-full mx-auto"
                />
              </Link>
              <Link className="mb-16" to="/manufacturer/Agilent-Technologies" target="_blank">
                <img
                  src={AgilentLogo}
                  alt="Agilent Logo"
                  className="object-contain w-full h-full mx-auto"
                />
              </Link>
              <Link className="mb-16" to="/manufacturer/Beckman-Coulter" target="_blank">
                <img
                  src={BeckmanCoulterLogo}
                  alt="Beckman Coulter Logo"
                  className="object-contain w-full h-full mx-auto"
                />
              </Link>
              <Link className="mb-16" to="/manufacturer/Bio-Rad" target="_blank">
                <img
                  src={BioRadLogo}
                  alt="Bio-rad Logo"
                  className="object-contain w-full h-full mx-auto"
                />
              </Link>
              <Link className="mb-16" to="/manufacturer/Bosch-Packaging-Technology" target="_blank">
                <img
                  src={BoschLogo}
                  alt="Bosch Logo"
                  className="object-contain w-full h-full mx-auto"
                />
              </Link>
              <Link className="mb-16" to="/manufacturer/Cytiva" target="_blank">
                <img
                  src={CytivaLogo}
                  alt="Cytiva Logo"
                  className="object-contain w-full h-full mx-auto"
                />
              </Link>
              <Link className="mb-16 pr-8" to="/manufacturer/GEA" target="_blank">
                <img
                  src={GEALogo}
                  alt="GEA Logo"
                  className="object-contain w-full h-full mx-auto"
                />
              </Link>
              <Link className="mb-16" to="/manufacturer/IMA" target="_blank">
                <img
                  src={IMALogo}
                  alt="IMA Group Logo"
                  className="object-contain w-full h-full mx-auto"
                />
              </Link>
            </Link>
            {/* 3 Logos */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-24 lg:gap-x-28 justify-items-center items-center text-center px-2 md:px-36">
              <Link className="mb-16" to="/manufacturer/Sartorius" target="_blank">
                <img
                  src={SartoriusLogo}
                  alt="Sartorius Logo"
                  className="object-contain w-full h-full mx-auto"
                />
              </Link>
              <Link className="mb-16" to="/manufacturer/Thermo-Fisher-Scientific" target="_blank">
                <img
                  src={ThermoFisherLogo}
                  alt="Thermo Fisher Scientific Logo"
                  className="object-contain w-full h-full mx-auto"
                />
              </Link>
              <Link className="mb-16" to="/manufacturer/Waters" target="_blank">
                <img
                  src={WatersLogo}
                  alt="Waters Logo"
                  className="object-contain w-full h-full mx-auto"
                />
              </Link>
            </div>
          </div>
        </div>

        <div className="bg-container-gray shadow-custom-in">
          <WrappingList
            title="Shop All Manufacturers"
            data={data.allManufacturer.nodes}
            slugPrefix={"../../manufacturer"}
          />
        </div>
        <ContactForm
          title="Never miss a great deal!"
          body={
            <p>
              Sign me up for Special Offers, New Arrivals, Case Studies, How-to
              Videos and More!
            </p>
          }
          fontShadowColor={colors.red}
          backgroundColor={colors.containerGray}
          shadow="shadow-red-bottom"
          formType="Marketing Sign Up"
        />
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allManufacturer(sort: { fields: [name], order: ASC }) {
      totalCount

      nodes {
        name
        description
        slug
      }
    }
  }
`

export default Manufacturers
