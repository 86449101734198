import React, { useState, useRef } from "react"
import SearchBox from "../layout/searchBox"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleChevronDown,
  faCircleChevronUp,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons"

const WrappingList = ({ data, title, slugPrefix, level3Mapping = {} }) => {
  const listRef = useRef(null)

  // Initialize alphabet structure with special character bucket "*", numbers, and letters
  const alphabet = {
    "#": [],
    ...Object.fromEntries(
      "abcdefghijklmnopqrstuvwxyz".split("").map((c) => [c, []])
    ),
  }

  // Populate the alphabet buckets
  data.forEach((listedItem) => {
    const firstLetter = listedItem.name[0].toLowerCase()
    if (alphabet[firstLetter]) {
      alphabet[firstLetter].push(listedItem)
    } else {
      alphabet["#"].push(listedItem)
    }
  })

  const keys = Object.keys(alphabet).filter((key) => alphabet[key].length > 0)

  const navigationKeys = ["ALL", ...keys]

  const [selectedKey, setSelectedKey] = useState("ALL")
  const [visibleItems, setVisibleItems] = useState(45) // Controls number of items shown
  const [expandedLevel2, setExpandedLevel2] = useState({}) // Tracks expanded Level 2 items for dropdown

  const scrollToTop = () => {
    if (listRef.current) {
      listRef.current.scrollIntoView({ behavior: "smooth" }) // Smooth scroll to the top of the list
    }
  }

  const handleToggleLevel2 = (slug) => {
    setExpandedLevel2((prevState) => {
      const newState = { ...prevState, [slug]: !prevState[slug] }
      return newState
    })
  }

  const getKeyIndex = (currentKey) => navigationKeys.indexOf(currentKey)

  const handlePrevious = () => {
    const currentIndex = getKeyIndex(selectedKey)
    const prevIndex =
      currentIndex > 0 ? currentIndex - 1 : navigationKeys.length - 1

    setSelectedKey(navigationKeys[prevIndex])
    setVisibleItems(45)
    scrollToTop()
  }

  const handleNext = () => {
    const currentIndex = getKeyIndex(selectedKey)
    const nextIndex =
      currentIndex < navigationKeys.length - 1 ? currentIndex + 1 : 0

    setSelectedKey(navigationKeys[nextIndex])
    setVisibleItems(45)
    scrollToTop()
  }

  const handleShowMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 45)
  }

  const displayedItems =
    selectedKey === "ALL" ? data : alphabet[selectedKey] || []

  return (
    <div
      ref={listRef}
      className="container max-w-screen-xl flex flex-col justify-between py-14 flex-wrap"
    >
      <h2 className="text-center mb-6">{title}</h2>
      <div className="md:px-16 lg:px-20 pb-10">
        <SearchBox />
      </div>
      {/* Alphabet Navigation */}
      <hr className="border-b-1" />
      <div className="flex flex-wrap justify-center gap-x-2 gap-y-1 md:flex-nowrap md:justify-between">
        {navigationKeys.map((key) => (
          <h5 key={key} className="relative mb-0">
            <button
              onClick={() => {
                setSelectedKey(key)
                setVisibleItems(45) // Reset visible items when switching sections
                scrollToTop() // Scroll to the top
              }}
              className={`transition-colors duration-200 text-[1.5rem]  ${
                key === selectedKey
                  ? "text-red border-b border-b-black"
                  : "text-gray hover:text-red"
              }`}
            >
              {key.toUpperCase()}
            </button>
          </h5>
        ))}
      </div>
      <hr className="border-b-1 mb-4" />

      {/* Display Filtered Data */}
      <div className="flex-wrap grid grid-cols-1 md:grid-cols-3 gap-3 text-left pb-4 border-b min-h-[30vh] auto-rows-min">
        {displayedItems.length > 0 ? (
          displayedItems
            .slice(0, visibleItems) // Limit displayed rows
            .map((item) => {
              const normalizedSlug = item.slug.replace(/\/$/, "") // Normalize slug
              return (
                <div key={item.slug} className="leading-none m-0 p-0 ">
                  <div className="flex items-center leading-none">
                    <Link
                      href={`${slugPrefix}${item.slug}`}
                      className="text-charcoal hover:text-red transition duration-200"
                    >
                      <h6 className="m-0 p-0 leading-none">{item.name}</h6>
                    </Link>
                    {level3Mapping[normalizedSlug] &&
                      level3Mapping[normalizedSlug].length > 0 && (
                        <button
                          onClick={() => handleToggleLevel2(normalizedSlug)}
                          className="pl-5 text-sm text-medium-gray hover:text-red"
                        >
                          {expandedLevel2[normalizedSlug] ? (
                            <FontAwesomeIcon icon={faCircleChevronUp} />
                          ) : (
                            <FontAwesomeIcon icon={faCircleChevronDown} />
                          )}
                        </button>
                      )}
                  </div>
                  {expandedLevel2[normalizedSlug] && (
                    <div className="ml-4">
                      {level3Mapping[normalizedSlug].map((level3Item) => (
                        <Link
                          key={level3Item.slug}
                          href={`${slugPrefix}${level3Item.slug}`}
                          className="block text-medium-gray hover:text-red"
                        >
                          <p>{level3Item.name}</p>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              )
            })
        ) : (
          <div className="col-span-3">No manufacturers found.</div>
        )}
      </div>

      {/* Navigation Buttons */}
      {/* Mobile Navigation */}
      <div className="flex flex-col items-center md:hidden text-charcoal mt-4">
        <div className="flex justify-between w-full">
          <button
            onClick={handlePrevious}
            className="hover:text-red font-light text-lg"
          >
            &lt; Previous Section
          </button>
          <button
            onClick={handleNext}
            className="hover:text-red font-light text-lg"
          >
            Next Section &gt;
          </button>
        </div>
        {displayedItems.length > visibleItems && (
          <div className="flex justify-center w-full mt-4 font-bold">
            <button
              onClick={handleShowMore}
              className="hover:text-red font-light text-lg"
            >
              <FontAwesomeIcon icon={faAngleDown} /> <b>Show More</b>
            </button>
          </div>
        )}
      </div>

      {/* Desktop Navigation */}
      <div className="hidden md:flex md:items-center md:justify-between text-charcoal mt-4">
        <button
          onClick={handlePrevious}
          className="hover:text-red font-light text-lg"
        >
          &lt; Previous Section
        </button>
        {displayedItems.length > visibleItems && (
          <button
            onClick={handleShowMore}
            className="hover:text-red font-light text-lg"
          >
            <FontAwesomeIcon icon={faAngleDown} /> <b>Show More</b>
          </button>
        )}
        <button
          onClick={handleNext}
          className="hover:text-red font-light text-lg"
        >
          Next Section &gt;
        </button>
      </div>
    </div>
  )
}

export default WrappingList
